//tinyMCE Globally import
import 'tinymce/tinymce';
// Theme
import 'tinymce/themes/silver';
// Toolbar icons
import 'tinymce/icons/default';
// Editor styles
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/plugins/link';
import 'tinymce/plugins/code';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/noneditable';
import 'tinymce/plugins/table';
import 'tinymce/plugins/autoresize';
import { routes } from "@utils/routes";
import { useRoutes } from "react-router";
import './style.css';
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import('customThemeUi.css')

window.Buffer = window.Buffer || require("buffer").Buffer;


const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnmount: false,
            refetchOnReconnect: false,
            retry: false,
        },
    },
});

function App() {

    const element = useRoutes(routes);

    return <QueryClientProvider client={queryClient}>
        {/*<UserFetcher>*/}
            {element}
        {/*</UserFetcher>*/}
    </QueryClientProvider>;
}

export default App;