import { Navigate } from "react-router";
import * as React from "react";
import {Suspense} from "react";
import LoadingView from "views/LoadingView/LoadingView";


const FacturationView = React.lazy(() => import("../views/FacturationView/Facture/FacturationView"));
const LoginView = React.lazy(() => import("@views/LoginView/LoginView"));
const IndexView = React.lazy(() => import("@views/IndexView/IndexView"));
const Guarded = React.lazy(() => import("@components/Guarded/Guarded"));
const NotFoundView = React.lazy(() => import("@views/NotFoundView/NotFoundView"));
const ParametresView = React.lazy(() => import("../views/ParametresView/ParametresView"));
const SendResetPasswordView = React.lazy(() => import("../views/ResetPasswordView/SendResetPasswordView"));
const ResetPasswordView = React.lazy(() => import("../views/ResetPasswordView/ResetPasswordView"));
const SendIdentifiantView = React.lazy(() => import("../views/ResetPasswordView/SendIdentifiantView"));
const CGUView = React.lazy(() => import("../views/CGUView/CGUView"));
const SuccessPaiementView = React.lazy(() => import("../views/communication/InvitationsView/Response/PaiementView/SuccessPaiementView"));
const CancelPaiementView = React.lazy(() => import("../views/communication/InvitationsView/Response/PaiementView/CancelPaiementView"));
const UserFetcher = React.lazy(() => import("../components/UserFetcher/UserFetcher"));
const AfficherRecuView = React.lazy(() => import("../views/communication/InvitationsView/AfficherRecuView"));
const IndexViewPublic = React.lazy(() => import("../views/IndexView/IndexViewPublic"));
const SuccessView = React.lazy(() => import("../views/ResetPasswordView/SuccessView"));
const HomeLoginView = React.lazy(() => import("../views/Home/HomeLoginView"));
const ReversionView = React.lazy(() => import("../views/FacturationView/Reversion/ReversionView"));
const ConfirmView = React.lazy(() => import("views/communication/InvitationsView/Response/ConfirmView"));
const RetourView = React.lazy(() => import("views/communication/InvitationsView/Response/PaiementView/RetourView"));
export const routes = [
    {
        path: '/',
        element:
            <Suspense fallback={<LoadingView/>}><IndexViewPublic/></Suspense>,
        children: [
            {
                path: 'login',
                element: <Suspense fallback={<LoadingView/>}><LoginView/></Suspense>,
            },
            {
                path: 'sendResetPassword',
                element: <Suspense fallback={<LoadingView/>}><SendResetPasswordView/></Suspense>,
            },
            {
                path: 'sendIdentifiant',
                element: <Suspense fallback={<LoadingView/>}><SendIdentifiantView/></Suspense>,
            },
            {
                path: 'success',
                element: <Suspense fallback={<LoadingView/>}><SuccessView/></Suspense>,
            },
            {
                path: 'utilisateur/demandeReinitialisationPassword',
                element: <Suspense fallback={<LoadingView/>}><ResetPasswordView/></Suspense>,
            },
            {
                path: 'verificationPaiement/succes',
                element: <Suspense fallback={<LoadingView/>}><SuccessPaiementView/></Suspense>,
            },
            {
                path: 'justificatif',
                element: <Suspense fallback={<LoadingView/>}><AfficherRecuView/></Suspense>,
            },
            {
                path: 'verificationPaiement/annule',
                element: <Suspense fallback={<LoadingView/>}><CancelPaiementView/></Suspense>,
            },
            {
                path: 'resume',
                element: <Suspense fallback={<LoadingView/>}><ConfirmView/></Suspense>,
            },
            {
                path: 'retour',
                element: <Suspense fallback={<LoadingView/>}><RetourView/></Suspense>,
            },
            {
                path: 'cgu',
                element: <Suspense fallback={<LoadingView/>}><CGUView /></Suspense>
            },
        ]
    },
    {
        path: '/',
        element: <Suspense fallback={<LoadingView/>}><UserFetcher><Guarded>
            <IndexView />
        </Guarded></UserFetcher></Suspense>,
        children: [
            { index: true, element: <Navigate to="facture" replace={true} /> },
            {
                path: 'facture',
                element:  <Suspense fallback={<LoadingView/>}><FacturationView /></Suspense>,
            },
            {
                path: 'reversion',
                element: <Suspense fallback={<LoadingView/>}><ReversionView /></Suspense>,
            },
            {
                path: 'parametres',
                element: <Suspense fallback={<LoadingView/>}><ParametresView /></Suspense>,
            },
            {
                path: 'parametres/stripe',
                element: <Suspense fallback={<LoadingView/>}><ParametresView stripe={true} /></Suspense>,
            }
        ],
    },
    {
        path: '/',
        element:
        <Suspense fallback={<LoadingView/>}><HomeLoginView /></Suspense>
    },
    { path: '*', element: <Suspense fallback={<LoadingView/>}><NotFoundView /></Suspense> },
];